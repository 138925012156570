import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import vSelect from 'vue-select'
import lodash from 'lodash'
import {
  ValidationObserver, ValidationProvider, extend, localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
import OtpInput from "@bachdgvn/vue-otp-input";

import * as VueGoogleMaps from 'vue2-google-maps'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'
import sharedMixin from './mixins/shared'
import './plugins/draggable'
// Theme Components
import './theme-components'
import './plugins/bootstrap'
import axiosPlugin from './plugins/axiosPlugin'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import { googleKey } from '@/services/url.service'

Object.keys(rules)
  .forEach(rule => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
    })
  })

localize({
  ar: {
    messages: ar.messages,
  },
  en: {
    messages: en.messages,
  },
})
extend('url', {
  validate: value => {
    const strongRegex = new RegExp(
      /^(http(s)?:\/\/)(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/,
    )
    return strongRegex.test(value)
  },
  message: 'The field is not a valid URL',
})
// Vue use Components
Vue.use(VueGoogleMaps, {
  load: {
    key: googleKey(),
    libraries: 'places',
  },
})
Vue.component('VSelect', vSelect)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCompositionAPI)
Vue.use(axiosPlugin)
Vue.use(lodash)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('v-otp-input', OtpInput)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// mixins
Vue.mixin(sharedMixin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
