<template>
  <div>
    <b-form-group :label="label">
      <b-form-datepicker
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        :formatted-value="value"
        :value="value"
        :placeholder="label"
        :locale="getLanguage"
        :label-help="''"
        :label-no-date-selected="''"
        :min="min"
        :max="max"
        :reset-button="reset"
        :label-reset-button="$t('labels.reset')"
        @context="onContext"
      />
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: ['label', 'value', 'min', 'max', 'reset'],
  computed: {
    getLanguage() {
      return localStorage.getItem('vuexy-direction') === 'rtl' ? 'ar' : 'en'
    },
  },
  methods: {
    onContext(ctx) {
      this.$emit('input', ctx.selectedYMD)
    },
  },
}
</script>
