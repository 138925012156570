import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...dashboard,
    ...pages,
    {
      path: '/',
      redirect: { name: 'dashboard-analytics' },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/views/profile/change-password/form.vue'),
      meta: {
        resource: 'change-password',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/list.vue'),
    },
    {
      path: '/users/add',
      name: 'add-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Add User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:resourceId',
      name: 'edit-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Edit User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/list.vue'),
    },
    {
      path: '/roles/add',
      name: 'add-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Add Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/:resourceId',
      name: 'edit-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Edit Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/:resourceId',
      name: 'roles-details',
      component: () => import('@/views/roles/details.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Role Details',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },

    {
      path: '/real-state-type',
      name: 'real-state-type',
      component: () => import('@/views/real-estate/types/list.vue'),
    },
    {
      path: '/real-state-type/add',
      name: 'add-real-state-type',
      component: () => import('@/views/real-estate/types/form.vue'),
      meta: {
        resource: 'real-estate-config/types',
        pageTitle: 'Add Real Estate Type',
        breadcrumb: [
          {
            to: { name: 'real-state-type' },
            text: 'Real Estate Type',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/real-state-type/:resourceId',
      name: 'edit-real-state-type',
      component: () => import('@/views/real-estate/types/form.vue'),
      meta: {
        resource: 'real-estate-config/types',
        pageTitle: 'Edit Real Estate Type',
        breadcrumb: [
          {
            to: { name: 'real-state-type' },
            text: 'Real Estate Type',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/newsletter',
      name: 'newsletter',
      component: () => import('@/views/newsletter/list.vue'),
    },
    {
      path: '/newsletter/add',
      name: 'add-newsletter',
      component: () => import('@/views/newsletter/form.vue'),
      meta: {
        resource: 'newsletter',
        pageTitle: 'Add Newsletter',
        breadcrumb: [
          {
            to: { name: 'newsletter' },
            text: 'News Letter',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/newsletter/:resourceId',
      name: 'edit-newsletter',
      component: () => import('@/views/newsletter/form.vue'),
      meta: {
        resource: 'newsletter',
        pageTitle: 'Edit Newsletter',
        breadcrumb: [
          {
            to: { name: 'newsletter' },
            text: 'News Letter',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/facilities-districts',
      name: 'facilities-districts',
      component: () => import('@/views/facilities-districts/list.vue'),
    },
    {
      path: '/facilities-districts/add',
      name: 'add-facilities-districts',
      component: () => import('@/views/facilities-districts/form.vue'),
      meta: {
        resource: 'facilities-districts',
        pageTitle: 'Add Facilities Districts',
        breadcrumb: [
          {
            to: { name: 'facilities-districts' },
            text: 'Facilities Districts',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/facilities-districts/:resourceId',
      name: 'edit-facilities-districts',
      component: () => import('@/views/facilities-districts/form.vue'),
      meta: {
        resource: 'facilities-districts',
        pageTitle: 'Edit Facilities Districts',
        breadcrumb: [
          {
            to: { name: 'facilities-districts' },
            text: 'Facilities Districts',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/facility-type',
      name: 'facilities-types',
      component: () => import('@/views/facilities-types/list.vue'),
    },
    {
      path: '/facility-type/add',
      name: 'add-facilities-types',
      component: () => import('@/views/facilities-types/form.vue'),
      meta: {
        resource: 'facilities-types',
        pageTitle: 'Add Facility Type',
        breadcrumb: [
          {
            to: { name: 'facilities-types' },
            text: 'facilities types',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/facility-type/:resourceId',
      name: 'edit-facilities-types',
      component: () => import('@/views/facilities-types/form.vue'),
      meta: {
        resource: 'facilities-types',
        pageTitle: 'Edit Facility Type',
        breadcrumb: [
          {
            to: { name: 'facilities-types' },
            text: 'facilities types',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: () => import('@/views/faqs/list.vue'),
    },
    {
      path: '/faqs/add',
      name: 'add-faq',
      component: () => import('@/views/faqs/form.vue'),
      meta: {
        resource: 'faqs',
        pageTitle: 'Add FAQ',
        breadcrumb: [
          {
            to: { name: 'faqs' },
            text: 'FAQ',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/faqs/:resourceId',
      name: 'edit-faq',
      component: () => import('@/views/faqs/form.vue'),
      meta: {
        resource: 'faqs',
        pageTitle: 'Edit FAQ',
        breadcrumb: [
          {
            to: { name: 'faqs' },
            text: 'FAQ',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/contact-us/list.vue'),
    },
    {
      path: '/contact-us/:resourceId/show',
      name: 'contact-details',
      meta: {
        resource: 'contact-us',
        pageTitle: 'contact-details',
        breadcrumb: [
          {
            to: { name: 'contact-us' },
            text: 'contact-us',
          },
          {
            text: 'contact-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/contact-us/show.vue'),
    },
    {
      path: '/contact-us/:resourceId',
      name: 'edit-contact-us',
      component: () => import('@/views/contact-us/form.vue'),
      meta: {
        resource: 'contact-us',
        pageTitle: 'Edit contact-us',
        breadcrumb: [
          {
            to: { name: 'contact-us' },
            text: 'contact-us',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/conflicts',
      name: 'conflicts',
      component: () => import('@/views/conflicts/list.vue'),
    },
    {
      path: '/conflicts/:resourceId/show',
      name: 'conflict-details',
      meta: {
        resource: 'conflicts',
        pageTitle: 'conflict-details',
        breadcrumb: [
          {
            to: { name: 'conflicts' },
            text: 'conflicts',
          },
          {
            text: 'conflict-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/conflicts/show.vue'),
    },
    {
      path: '/related-service',
      name: 'related-service',
      component: () => import('@/views/related-service/list.vue'),
    },
    {
      path: '/related-service/add',
      name: 'add-related-service',
      component: () => import('@/views/related-service/form.vue'),
      meta: {
        resource: 'related-service',
        pageTitle: 'Add Related Service',
        breadcrumb: [
          {
            to: { name: 'related-service' },
            text: 'Related Service',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/related-service/:resourceId',
      name: 'edit-related-service',
      component: () => import('@/views/related-service/form.vue'),
      meta: {
        resource: 'related-service',
        pageTitle: 'Edit Related Service',
        breadcrumb: [
          {
            to: { name: 'related-service' },
            text: 'Related Service',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      meta: {
        resource: 'settings',
      },
      component: () => import('@/views/settings/form.vue'),
    },

    {
      path: '/blogs',
      name: 'blogs',
      component: () => import('@/views/blogs/list.vue'),
    },
    {
      path: '/blogs/add',
      name: 'add-blog',
      component: () => import('@/views/blogs/form.vue'),
      meta: {
        resource: 'blogs',
        pageTitle: 'Add News',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blogs/:resourceId',
      name: 'edit-blog',
      component: () => import('@/views/blogs/form.vue'),
      meta: {
        resource: 'blogs',
        pageTitle: 'Edit News',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-views/:blog_id',
      name: 'news-views',
      component: () => import('@/views/blogs/news-views.vue'),
      meta: {
        resource: 'customer-blog-views',
        pageTitle: 'News Views',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'News Views',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-ratings/:blog_id',
      name: 'news-ratings',
      component: () => import('@/views/blogs/news-ratings.vue'),
      meta: {
        resource: 'blogs-ratings',
        pageTitle: 'News Ratings',
        breadcrumb: [
          {
            to: { name: 'blogs' },
            text: 'News',
          },
          {
            text: 'News Ratings',
            active: true,
          },
        ],
      },
    },

    {
      path: '/blog-categories',
      name: 'blog-categories',
      component: () => import('@/views/blog-categories/list.vue'),
    },
    {
      path: '/blog-categories/add',
      name: 'add-blog-category',
      component: () => import('@/views/blog-categories/form.vue'),
      meta: {
        resource: 'blog-categories',
        pageTitle: 'Add News Category',
        breadcrumb: [
          {
            to: { name: 'blog-categories' },
            text: 'News Category',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog-categories/:resourceId',
      name: 'edit-blog-category',
      component: () => import('@/views/blog-categories/form.vue'),
      meta: {
        resource: 'blog-categories',
        pageTitle: 'Edit News Category',
        breadcrumb: [
          {
            to: { name: 'blog-categories' },
            text: 'News Category',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages-builder',
      name: 'pages-builder',
      component: () => import('@/views/page-builder/list.vue'),
    },
    {
      path: '/pages-builder/add',
      name: 'add-page-builder',
      component: () => import('@/views/page-builder/form.vue'),
      meta: {
        resource: 'pages-builder',
        pageTitle: 'Add Page',
        breadcrumb: [
          {
            to: { name: 'pages-builder' },
            text: 'Pages',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages-builder/:resourceId',
      name: 'edit-page-builder',
      component: () => import('@/views/page-builder/form.vue'),
      meta: {
        resource: 'pages-builder',
        pageTitle: 'Edit Page',
        breadcrumb: [
          {
            to: { name: 'pages-builder' },
            text: 'Pages',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages-builder/:resourceId/show',
      name: 'page-details',
      meta: {
        resource: 'pages-builder',
        pageTitle: 'page-details',
        breadcrumb: [
          {
            to: { name: 'pages-builder' },
            text: 'Pages',
          },
          {
            text: 'page-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/page-builder/show.vue'),
    },
    {
      path: '/advertisements',
      name: 'advertisements',
      component: () => import('@/views/advertisements/list.vue'),
    },
    {
      path: '/advertisements/add',
      name: 'add-advertisement',
      component: () => import('@/views/advertisements/form.vue'),
      meta: {
        resource: 'advertisements',
        pageTitle: 'Add Advertisement',
        breadcrumb: [
          {
            to: { name: 'advertisements' },
            text: 'Advertisements',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/advertisements/:resourceId',
      name: 'edit-advertisement',
      component: () => import('@/views/advertisements/form.vue'),
      meta: {
        resource: 'advertisements',
        pageTitle: 'Edit Advertisements',
        breadcrumb: [
          {
            to: { name: 'advertisements' },
            text: 'Advertisements',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/ads-category',
      name: 'advertisements/categories',
      component: () => import('@/views/ads-category/list.vue'),
    },
    {
      path: '/ads-category/add',
      name: 'add-ads-category',
      component: () => import('@/views/ads-category/form.vue'),
      meta: {
        resource: 'advertisements/categories',
        pageTitle: 'Add Ads Category',
        breadcrumb: [
          {
            to: { name: 'advertisements/categories' },
            text: 'Ads Category',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ads-category/:resourceId',
      name: 'edit-ads-category',
      component: () => import('@/views/ads-category/form.vue'),
      meta: {
        resource: 'advertisements/categories',
        pageTitle: 'Edit Ads Category',
        breadcrumb: [
          {
            to: { name: 'advertisements/categories' },
            text: 'Ads Category',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/countries',
      name: 'countries',
      component: () => import('@/views/countries/list.vue'),
    },
    {
      path: '/countries/add',
      name: 'add-country',
      component: () => import('@/views/countries/form.vue'),
      meta: {
        resource: 'countries',
        pageTitle: 'Add Country',
        breadcrumb: [
          {
            to: { name: 'countries' },
            text: 'Countries',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/countries/:resourceId',
      name: 'edit-country',
      component: () => import('@/views/countries/form.vue'),
      meta: {
        resource: 'countries',
        pageTitle: 'Edit Country',
        breadcrumb: [
          {
            to: { name: 'countries' },
            text: 'Countries',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/regions',
      name: 'regions',
      component: () => import('@/views/countries/regions/list.vue'),
    },
    {
      path: '/regions/add',
      name: 'add-region',
      meta: {
        resource: 'regions',
        pageTitle: 'Add Regions',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'regions' },
            text: 'Regions',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/regions/form.vue'),
    },
    {
      path: '/regions/:resourceId/edit',
      name: 'edit-region',
      meta: {
        resource: 'regions',
        pageTitle: 'Edit Regions',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'regions' },
            text: 'Regions',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/regions/form.vue'),
    },
    {
      path: '/cities',
      name: 'cities',
      component: () => import('@/views/countries/cities/list.vue'),
    },
    {
      path: '/cities/add',
      name: 'add-city',
      meta: {
        resource: 'cities',
        pageTitle: 'Add Cities',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'cities' },
            text: 'Cities',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/cities/form.vue'),
    },
    {
      path: '/cities/:resourceId/edit',
      name: 'edit-city',
      meta: {
        resource: 'cities',
        pageTitle: 'Edit Cities',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'cities' },
            text: 'Cities',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/cities/form.vue'),
    },
    {
      path: '/districts',
      name: 'districts',
      component: () => import('@/views/countries/districts/list.vue'),
    },
    {
      path: '/districts/add',
      name: 'add-district',
      meta: {
        resource: 'districts',
        pageTitle: 'Add Districts',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'districts' },
            text: 'Districts',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/districts/form.vue'),
    },
    {
      path: '/districts/:resourceId/edit',
      name: 'edit-district',
      meta: {
        resource: 'districts',
        pageTitle: 'Edit Districts',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'districts' },
            text: 'Districts',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/countries/districts/form.vue'),
    },
    {
      path: '/customers',
      name: 'customers',
      meta: {
        resource: 'customers',
        pageTitle: 'Customers',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Customers',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/list.vue'),
    },
    {
      path: '/customers/add',
      name: 'add-customer',
      meta: {
        resource: 'customers',
        pageTitle: 'Add customer',
        type: 'add',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/form.vue'),
    },
    {
      path: '/customers/:resourceId/edit',
      name: 'edit-customer',
      meta: {
        resource: 'customers',
        pageTitle: 'Edit customers',
        type: 'edit',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/form.vue'),
    },
    {
      path: '/favorite-ads/:customer_id',
      name: 'favorite-ads',
      meta: {
        resource: 'real-estate/likes',
        pageTitle: 'Favorite Ads',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Favorite Ads',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/favorite-ads/list.vue'),
    },
    {
      path: '/customer-search/:customer_id',
      name: 'customer-search',
      meta: {
        resource: 'real-estate-views',
        pageTitle: 'Customer Search',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Customer Search',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/search-archive/list.vue'),
    },
    {
      path: '/advertiser-ratings/:customer_id',
      name: 'advertiser-ratings',
      meta: {
        resource: 'advertiser-ratings',
        pageTitle: 'Advertiser Ratings',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'Advertiser Ratings',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/advertiser-ratings/list.vue'),
    },
    {
      path: '/comparisons-logs',
      name: 'comparisons-logs',
      meta: {
        resource: 'comparisons-logs',
        pageTitle: 'comparisons-logs',
        breadcrumb: [
          {
            to: { name: 'customers' },
            text: 'customers',
          },
          {
            text: 'comparison logs',
            active: true,
          },
        ],
      },
      component: () => import('@/views/customers/comparisons-logs/list.vue'),
    },
    {
      path: '/real-estate',
      name: 'real-estate',
      component: () => import('@/views/real-estate/list.vue'),
    },
    {
      path: '/real-estate/add',
      name: 'add-real-estate',
      meta: {
        resource: 'real-estate',
        pageTitle: 'add-real-estate',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },
    {
      path: '/real-estate/:resourceId/edit',
      name: 'edit-real-estate',
      meta: {
        resource: 'real-estate',
        pageTitle: 'edit-real-estate',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/form.vue'),
    },
    {
      path: '/real-estate/:resourceId/show',
      name: 'details-real-estate',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-details',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/show.vue'),
    },
    {
      path: '/real-estate/:resourceId/images/show',
      name: 'show-images-real-estate',
      meta: {
        resource: 'real-estate',
        pageTitle: 'real-estate-images',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-images',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/images/details.vue'),
    },
    {
      path: '/real-estate/:resourceId/ratings/:rateable_id',
      name: 'real-estate-ratings',
      meta: {
        resource: 'real-estate-ratings',
        pageTitle: 'real-estate-ratings',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-ratings',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/ratings/details.vue'),
    },
    {
      path: '/real-estate/:resourceId/call-evaluation',
      name: 'real-estate-call-evaluation',
      meta: {
        resource: 'real-estate-call-evaluation',
        pageTitle: 'real-estate-call-evaluation',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-call-evaluation',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/call-evaluation/details.vue'),
    },
    {
      path: '/real-estate/:resourceId/views',
      name: 'real-estate-views',
      meta: {
        resource: 'real-estate-views',
        pageTitle: 'real-estate-views',
        breadcrumb: [
          {
            to: { name: 'real-estate' },
            text: 'real-estates',
          },
          {
            text: 'real-estate-views',
            active: true,
          },
        ],
      },
      component: () => import('@/views/real-estate/views/details.vue'),
    },
    {
      path: '/real-estate-view',
      name: 'real-estate-view',
      component: () => import('@/views/real-estate/real-estate-view.vue'),
    },
    {
      path: '/real-estates/:slug/update-expiration-date',
      name: 'real-estates-update-expiration-date',
      component: () => import('@/views/statuses/modal.vue'),
    },
    {
      path: '/sale-requests',
      name: 'sale-requests',
      component: () => import('@/views/sale-requests/list.vue'),
    },
    {
      path: '/sale-requests/:resourceId/show',
      name: 'sale-requests-details',
      meta: {
        resource: 'sale-requests',
        pageTitle: 'sale-request-details',
        breadcrumb: [
          {
            to: { name: 'sale-requests' },
            text: 'sale-requests',
          },
          {
            text: 'sale-request-details',
            active: true,
          },
        ],
      },
      component: () => import('@/views/sale-requests/show.vue'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
