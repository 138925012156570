<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">{{ $t('labels.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.direction ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="mb-0">{{ $t('labels.rows') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div
              class="d-flex align-items-center justify-content-end"
              style="gap: 10px;"
            >
              <slot
                name="table-header-link"
              />
              <router-link
                v-if="generalActions.can_create"
                v-slot="{ navigate }"
                :to="addRoute"
                class="btn btn-primary"
                custom
              >
                <span
                  class="text-capitalize"
                  role="link"
                  @click="navigate"
                  @keypress.enter="navigate"
                >{{ addLabel }}</span>
              </router-link>
              <b-dropdown
                v-if="generalActions.can_import || generalActions.can_export"
                :right="!$store.state.appConfig.isRTL"
                class="ml-1"
                :text="$t('Tools')"
                variant="primary"
              >
                <b-dropdown-item v-if="generalActions.can_import">
                  <span class="align-middle ml-50">{{ $t('Import') }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="generalActions.can_export"
                  @click.prevent="exportTable"
                >
                  <span class="align-middle ml-50">{{ $t('Export') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        responsive
        :items="items"
        :fields="fields"
        :sort-desc.sync="isSortDirDesc"
        :hover="true"
        show-empty
        :empty-text="$t('messages.there_is_no_data_to_display')"
        class="mb-0"
      >
        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>

        <template #cell(image)="data">
          <b-avatar
            :src="data.item.image_path"
            rounded
            size="4rem"
          />
        </template>
        <template #cell(icon)="data">
          <b-avatar
            :src="data.item.icon"
            rounded
            size="4rem"
          />
        </template>
        <template #cell(image_path)="data">
          <b-avatar
            :src="data.item.image_path"
            rounded
            size="4rem"
          />
        </template>

        <template #cell(logo)="data">
          <b-avatar
            :src="data.item.logo"
            rounded
            size="4rem"
          />
        </template>
        <!-- Row: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="statusVariant(data.item.status)"
            class="text-capitalize"
          >
            {{ data.item.status_value }}
          </b-badge>
        </template>
        <!-- Row: Action -->
        <template #cell(created_at)="data">
          {{ formatDate(data.item.created_at) }}
        </template>
        <template #cell(updated_at)="data">
          {{ formatDate(data.item.updated_at) }}
        </template>
        <template #cell(deleted_at)="data">
          {{ formatDate(data.item.deleted_at) }}
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <div
              v-if="(data.item.actions && data.item.actions.can_change_status) && canChangeStatus"
              class="position-relative"
            >
              <b-form-checkbox
                v-model="data.item.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
              <div
                class="switch-layer"
                @click.prevent="changeStatus($event, data.item)"
              />
            </div>
            <b-link
              v-if="(data.item.actions && data.item.actions.can_update) && editRoute"
              v-b-tooltip.hover.v-primary
              :title="$t('Edit')"
              :to="{ name: editRoute, params: { resourceId: data.item.id } }"
              class="ml-1"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                class="align-middle text-body"
              />
            </b-link>
            <b-link
              v-if="(data.item.actions && data.item.actions.can_delete)"
              v-b-tooltip.hover.v-danger
              :title="$t('Delete')"
              class="ml-1"
              @click.prevent="showDeleteModal(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="align-middle text-body"
              />
            </b-link>
            <slot
              v-if="slotCustomActions"
              name="custom_actions"
              :item="data.item"
              :generalActions="generalActions"
            />
            <b-dropdown
              v-if="haveDropdownActions"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <slot
                v-if="slotDropDownActions"
                name="dropdown_actions"
                :item="data.item"
                :generalActions="generalActions"
              />
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('labels.total') }} <span class="text-capitalize">
              {{ $t('models.model_title', { title: $t(`models.${resourceName}`) }) }}
            </span>
              : {{ totalRows }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!-- modal -->
    <b-modal
      id="delete-modal"
      ref="delete-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ $t('messages.are_you_sure_to_delete_this_item') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant="danger"
          @click="confirmDelete"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
    <!-- modal -->
    <b-modal
      id="change-status-modal"
      ref="change-status-modal"
      modal-class="modal-primary"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ $t('messages.are_you_sure_to_change_status') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="confirmChangeStatus"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'TableIndex',
  props: {
    sortBy: {
      type: String,
      default: 'id',
    },
    sortDir: {
      type: Boolean,
      default: true,
    },
    singularName: {
      type: String,
      required: true,
    },
    canChangeStatus: {
      type: Boolean,
      default() {
        return true
      },
    },
    resourceExport: {
      type: String,
      default() {
        return ''
      },
    },
    haveDropdownActions: {
      type: Boolean,
      default() {
        return false
      },
    },
    statuses: {
      type: Array,
      default() {
        return ['active', 'inactive']
      },
    },
    resourceName: {
      type: String,
      required: true,
    },
    addRoute: {
      type: Object,
      default() {
        return {
          name: `add-${this.singularName.toLocaleLowerCase()}`,
        }
      },
    },
    editRoute: {
      type: String,
      default() {
        return `edit-${this.singularName.toLocaleLowerCase()}`
      },
    },
    filterFields: {
      type: Object,
      default() {
        return {}
      },
    },
    filterMultiple: {
      type: Object,
      default() {
        return {}
      },
    },
    customChangeStatus: {
      type: Object,
      default: null,
    },
    customUpdateObject: {
      type: Object,
      default: null,
    },
    statusObject: {
      type: Object,
      default() {
        return null
      },
    },
    canSplice: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      generalActions: {
        can_export: false,
        can_import: false,
      },
      headersList: [],
      items: [],
      isSortDirDesc: this.sortDir,
      selectedItem: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Current: 'light-primary',
        active: 'light-success',
        inactive: 'light-danger',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        canceled: 'light-danger',
        pending: 'light-warning',
        archived: 'light-warning',
        approved: 'light-success',
        reported: 'light-primary',
        Canceled: 'light-danger',
        Pending: 'light-warning',
        Archived: 'light-warning',
        Approved: 'light-success',
        Reported: 'light-primary',
        accepted: 'light-success',
        closed: 'light-info',
        replied: 'light-success',
        read: 'light-success',
      }

      return status => statusColor[status]
    },
    addLabel() {
      return `${this.$t('general.add_model', { models: this.$t(`${this.singularName.replace(/-/g, ' ')}`) })}`
    },
    fields() {
      const headers = this.headersList
      headers.forEach((part, index) => {
        if (['actions'].includes(part.key)) {
          headers[index] = {
            ...part,
            class: 'col-small',
          }
        }
      }, headers)
      return headers
    },
    statusLabels() {
      return {
        active: this.$t('statuses.active'),
        inactive: this.$t('statuses.inactive'),
      }
    },
    slotCustomActions() {
      return this.$scopedSlots.custom_actions
    },
    slotDropDownActions() {
      return this.$scopedSlots.dropdown_actions
    },
  },
  watch: {
    currentPage() {
      this.getData()
    },
    perPage() {
      this.getData()
    },
    filterFields: {
      deep: true,
      handler() {
        this.getData()
      },
    },
    customChangeStatus: {
      handler(val) {
        const $idx = this.items.findIndex(el => el.id === val.id)
        this.items[$idx].status = val.status
        this.items[$idx].status_value = val.status_value
      },
    },
    customUpdateObject: {
      handler(val) {
        const $idx = this.items.findIndex(el => el.id === val.id)
        this.items[$idx].actions = val.actions
        this.items[$idx].status = val.status
        this.items[$idx].status_value = val.status_value
      },
    },
    statusObject: {
      handler(val) {
        const $idx = this.items.findIndex(el => el.id === val.id)
        this.items[$idx].status = val.status
        this.items[$idx].status_value = this.$t(`statuses.${val.status.toLowerCase()}`)
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
    },
    getData() {
      return new Promise((resolve, reject) => {
        const params = {
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          sort_dir: this.isSortDirDesc ? 'desc' : 'asc',
          ...this.filterFields,
        }
        this.axios
          .get(`/${this.resourceName}`, { params })
          .then(response => {
            this.items = response.data.data
            this.totalRows = response.data.meta ? response.data.meta.total : ''
            this.generalActions = {
              ...this.generalActions,
              ...response.data.actions,
            }
            this.headersList = response.data.headers
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    showDeleteModal(item) {
      this.selectedItem = item
      this.$refs['delete-modal'].show()
    },
    confirmDelete() {
      const item = this.selectedItem
      const route = `/${this.resourceName}/${this.selectedItem.id}`
      this.axios
        .delete(route)
        .then(res => {
          this.$refs['delete-modal'].hide()
          const $idx = this.items.indexOf(item)
          if (this.canSplice) {
            this.items.splice($idx, 1)
          } else {
            this.getData()
          }
          this.selectedItem = null
          this.$bvToast.toast(res.data.message, {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
            appendToast: true,
          })
        })
        .catch(error => {
          this.$refs['delete-modal'].hide()
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
            appendToast: true,
          })
        })
    },
    changeStatus($e, item) {
      this.$refs['change-status-modal'].show()
      this.selectedItem = item
    },
    confirmChangeStatus() {
      this.$refs['change-status-modal'].hide()
      const $item = this.selectedItem
      const $idx = this.items.indexOf($item)
      const statusIdx = this.statuses.indexOf($item.status) === 0 ? 1 : 0
      const toStatus = this.statuses[statusIdx]
      const $statusType = {
        status: toStatus,
      }
      const $link = `/${this.resourceName}/${$item.id}/change-status`
      this.axios
        .patch($link, $statusType)
        .then(res => {
          this.$bvToast.toast(res.data.message, {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
          })
          this.items[$idx].status = toStatus
          this.items[$idx].status_value = this.statusLabels[toStatus]
          this.selectedItem = null
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    exportAction(fields) {
      const resourceExport = this.resourceExport ? `${this.resourceExport}` : `${this.resourceName}`
      let route = `/${resourceExport}/export`
      const searchFields = {}
      Object.keys(this.filterFields)
        .forEach(key => {
          if (this.filterFields[key] !== null) {
            searchFields[key] = this.filterFields[key]
          }
        })
      const $params = new URLSearchParams(searchFields).toString()
      if (fields.length) {
        let $fields = ''
        fields.forEach((x, i) => {
          $fields += `${i > 0 ? '&' : ''}fields[]=${x}`
        })
        route += `?${$fields}&${$params}`
      } else {
        route += `?${$params}`
      }
      this.axios
        .get(route)
        .then(res => {
          this.$bvToast.toast(this.$t('export_success'), {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
          })
          if (res.data.data.file) {
            window.open(res.data.data.file, '_blank')
              .focus()
          }
        })
        .catch(error => {
          this.$bvToast.toast(error.response?.data?.message, {
            title: this.$t('general.Error'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    exportTable() {
      this.exportAction([])
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.col-small {
  width: 180px;
}

.col-w-100 {
  width: 200px;
}

.switch-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

html[dir=rtl] td .dropdown-menu {
  right: -7rem
}

html[dir=ltr] td .dropdown-menu {
  left: -7rem
}

[dir] .dark-layout .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, [dir] .dark-layout .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  min-width: 140px;
}

[dir] .dark-layout .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th:first-child, [dir] .dark-layout .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th:first-child {
  min-width: 80px;
}

[dir] .table th, [dir] .table td {
  padding: 0.72rem 1rem !important;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
